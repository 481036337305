<script setup lang="ts">
import emitter from '@/utils/mitt'

const { user } = storeToRefs(useUserStore())
const route = useRoute()
const appStore = useAppStore()
const url = computed(() => route?.meta?.url)
const iframeDom = ref()
const loading = ref(true)
function finishLoading() {
  loading.value = false
}

appStore.layoutSetting.menu = false

onUnmounted(() => {
  appStore.layoutSetting.menu = true
})

onMounted(() => {
  // const localFrame = useRoute().meta.localFrame
  // if (localFrame) {
  const iframeWindow = iframeDom.value.contentWindow
  iframeDom.value.onload = () => {
    const data = {
      type: 'chenye',
      data: {
        cookie: getLoginCookie(),
        user: user.value,
        parentOrigin: window.location.origin,
      },
    }

    // 参数: 1.要发送的数据, 2.目标域名
    iframeWindow.postMessage(data, '*')
  }

  emitter.on('onLogout', () => {
    iframeWindow.postMessage({ type: 'logout', data: 'logout' }, '*')
  })
  // }
})

function getLoginCookie() {
  const cookies = document.cookie.match(/(?:^|;)\s*chenye-iot-canary-sid=([^;]+)/)
  if (cookies != null && cookies.length > 1) {
    return cookies[1]
  }
  return null
}
</script>

<template>
  <div
    class="bg-[var(--bg-color)] ant-pro-iframe-wrap"
    w-full h-full of-hidden
    flex flex-col flex-1
  >
    <a-spin
      :spinning="loading"
      wrapper-class-name="of-hidden w-full h-full flex flex-col flex-1"
    >
      <iframe ref="iframeDom" w-full h-full flex flex-col flex-1 :src="url" style="border: none" @load="finishLoading" />
    </a-spin>
  </div>
</template>

<style>
.ant-pro-iframe-wrap{
  .ant-spin-container{
    height: 100% !important;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
</style>
